import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { mergeDeepRight } from 'ramda'
import { getDataMode, getLanguage } from './selectorsV2'
import * as t from './typesV2'

const createAction =
  (actionCreator) => (payload, meta) => (dispatch, getState) => {
    const state = getState()
    const dataMode = getDataMode(state)
    const languageString = getLanguage(state)
    const language = langDetectObjectsByType1[languageString]

    const action = actionCreator(
      mergeDeepRight(payload, {
        language,
        dataMode,
        analytics: { dataMode },
      }),
      meta
    )

    action.shouldAdjustData = false

    dispatch(action)
  }

export const createGroup = createAction((payload) => ({
  type: t.CREATE_GROUP,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/group/create' },
      commit: { type: t.CREATE_GROUP_SUCCESS },
      rollback: { type: t.CREATE_GROUP_ERROR },
    },
  },
}))

export const createGroups = createAction((payload) => ({
  type: t.CREATE_GROUPS,
  payload,
  meta: {
    offline: {
      effect: {
        method: 'POST',
        path: 'admin/artificialtells/v2/group/create-bulk',
      },
      commit: { type: t.CREATE_GROUPS_SUCCESS, meta: { payload } },
      rollback: { type: t.CREATE_GROUPS_ERROR, meta: { payload } },
    },
  },
}))

export const createTodo = (payload) => ({
  type: t.CREATE_TODO,
  shouldAdjustData: false,
  payload: {
    ...payload,
    language: langDetectObjectsByType1[payload.language],
  },
  meta: {
    offline: {
      effect: {
        method: 'POST',
        path: 'admin/artificialtells/v2/todo/create',
      },
      commit: { type: t.CREATE_TODO_SUCCESS },
      rollback: { type: t.CREATE_TODO_ERROR },
    },
  },
})

export const refreshTodos = (payload) => ({
  type: t.REFRESH_TODOS,
  shouldAdjustData: false,
  payload,
  meta: {
    offline: {
      effect: {
        limit: null,
        method: 'GET',
        path: 'admin/artificialtells/v2/todo',
        ...payload,
      },
      commit: { type: t.REFRESH_TODOS_SUCCESS },
      rollback: { type: t.REFRESH_TODOS_ERROR },
    },
  },
})

export const completeTodo = (payload) => ({
  type: t.COMPLETE_TODO,
  shouldAdjustData: false,
  payload: { id: payload.todoId },
  meta: {
    offline: {
      effect: {
        method: 'POST',
        path: 'admin/artificialtells/v2/todo/complete',
      },
      commit: { type: t.COMPLETE_TODO_SUCCESS },
      rollback: { type: t.COMPLETE_TODO_ERROR },
    },
  },
})

export const createVariance = createAction((payload) => ({
  type: t.CREATE_VARIANCE,
  payload,
  meta: {
    offline: {
      effect: {
        method: 'POST',
        path: 'admin/artificialtells/v2/variance/create',
      },
      commit: { type: t.CREATE_VARIANCE_SUCCESS },
      rollback: { type: t.CREATE_VARIANCE_ERROR },
    },
  },
}))

export const createVariances = createAction((payload) => ({
  type: t.CREATE_VARIANCES,
  payload,
  meta: {
    offline: {
      effect: {
        method: 'POST',
        path: 'admin/artificialtells/v2/variance/create-bulk',
      },
      commit: { type: t.CREATE_VARIANCES_SUCCESS },
      rollback: { type: t.CREATE_VARIANCES_ERROR },
    },
  },
}))

export const editGroup = createAction((payload) => ({
  type: t.EDIT_GROUP,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/group/update' },
      commit: { type: t.EDIT_GROUP_SUCCESS, meta: payload },
      rollback: { type: t.EDIT_GROUP_ERROR },
    },
  },
}))

export const createTopic = createAction((payload) => ({
  type: t.CREATE_TOPIC,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/topic/create' },
      commit: { type: t.CREATE_TOPIC_SUCCESS },
      rollback: { type: t.CREATE_TOPIC_ERROR },
    },
  },
}))

export const editTopic = createAction((payload) => ({
  type: t.EDIT_TOPIC,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/topic/update' },
      commit: { type: t.EDIT_TOPIC_SUCCESS },
      rollback: { type: t.EDIT_TOPIC_ERROR },
    },
  },
}))

export const editVariance = createAction((payload) => ({
  type: t.EDIT_VARIANCE,
  payload,
  meta: {
    offline: {
      effect: {
        method: 'POST',
        path: 'admin/artificialtells/v2/variance/update',
      },
      commit: { type: t.EDIT_VARIANCE_SUCCESS },
      rollback: { type: t.EDIT_VARIANCE_ERROR },
    },
  },
}))

export const refreshGroup = createAction((payload, meta) => {
  const { language, dataMode, groupId, showDeletedVariances } = payload

  return {
    type: t.REFRESH_GROUP,
    payload,
    meta: {
      ...meta,
      offline: {
        effect: {
          path: `admin/artificialtells/v2/group/details?lang=${language}&id=${groupId}`,
          dataMode,
          showDeletedVariances,
        },
        commit: { type: t.REFRESH_GROUP_SUCCESS },
        rollback: { type: t.REFRESH_GROUP_ERROR },
      },
    },
  }
})

export const refreshVarianceAnalytics = createAction((payload) => {
  const { dataMode, varianceIds, language } = payload

  return {
    type: t.REFRESH_VARIANCE_ANALYTICS,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/artificialtells/v2/variances/analytics',
          varianceIds,
          dataMode,
          language,
        },
        commit: { type: t.REFRESH_VARIANCE_ANALYTICS_SUCCESS, meta: { payload } }, // prettier-ignore
        rollback: { type: t.REFRESH_VARIANCE_ANALYTICS_ERROR },
      },
    },
  }
})

export const refreshTopic = createAction((payload) => {
  const { language, dataMode, topicId } = payload

  return {
    type: t.REFRESH_TOPIC,
    payload,
    meta: {
      offline: {
        effect: {
          path: `admin/artificialtells/v2/topic/details?id=${topicId}`,
          lang: language,
          dataMode,
        },
        commit: { type: t.REFRESH_TOPIC_SUCCESS },
        rollback: { type: t.REFRESH_TOPIC_ERROR },
      },
    },
  }
})

export const refreshTopics = createAction((payload) => {
  const { language, dataMode } = payload

  return {
    type: t.REFRESH_TOPICS,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/artificialtells/v2/topics',
          lang: language,
          dataMode,
        },
        commit: { type: t.REFRESH_TOPICS_SUCCESS },
        rollback: { type: t.REFRESH_TOPICS_ERROR },
      },
    },
  }
})

export const refreshUnderperformingGroups = createAction((payload) => {
  const { language, dataMode } = payload

  return {
    type: t.REFRESH_UNDERPERFORMING_GROUPS,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/artificialtells/v2/groups/underperforming',
          language,
          dataMode,
        },
        commit: { type: t.REFRESH_UNDERPERFORMING_GROUPS_SUCCESS },
        rollback: { type: t.REFRESH_UNDERPERFORMING_GROUPS_ERROR },
      },
    },
  }
})

export const setDataMode = (payload) => ({
  type: t.SET_DATA_MODE,
  payload,
})

export const setHeaderMode = (payload) => ({
  type: t.SET_HEADER_MODE,
  payload,
})

export const setLanguage = (payload) => ({
  type: t.SET_LANGUAGE,
  payload,
})

export const setShouldShowDeletedVariances = (payload) => ({
  type: t.SET_SHOULD_SHOW_DELETED_VARIANCES,
  payload,
})

/**
 * Speed Translations
 */
export const generateSpeedTranslations = createAction((payload, meta) => {
  const { gender } = meta ?? {}

  return {
    type: t.GENERATE_SPEED_TRANSLATIONS,
    payload,
    meta: {
      gender,
      offline: {
        effect: {
          method: 'POST',
          path: 'admin/artificialtells/v2/group/translate',
        },
        commit: { type: t.GENERATE_SPEED_TRANSLATIONS_SUCCESS, meta: { gender, payload } }, // prettier-ignore
        rollback: { type: t.GENERATE_SPEED_TRANSLATIONS_ERROR, meta: { gender, payload } }, // prettier-ignore
      },
    },
  }
})

export const clearSpeedTranslations = (payload) => ({
  type: t.CLEAR_SPEED_TRANSLATIONS,
  payload,
})

/**
 * Speed Generation
 */
export const generateSpeedContent = createAction((payload) => {
  return {
    type: t.GENERATE_SPEED_CONTENT,
    payload,
    meta: {
      offline: {
        effect: {
          method: 'POST',
          path: 'admin/artificialtells/v2/groups/generate',
        },
        commit: { type: t.GENERATE_SPEED_CONTENT_SUCCESS, meta: { payload } }, // prettier-ignore
        rollback: { type: t.GENERATE_SPEED_CONTENT_ERROR, meta: { payload } }, // prettier-ignore
      },
    },
  }
})

export const generateSpeedContentParams = createAction((payload) => {
  return {
    type: t.GENERATE_SPEED_CONTENT_PARAMS,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/artificialtells/v2/groups/generate-params',
          ...payload,
        },
        commit: { type: t.GENERATE_SPEED_CONTENT_PARAMS_SUCCESS, meta: { payload } }, // prettier-ignore
        rollback: { type: t.GENERATE_SPEED_CONTENT_PARAMS_ERROR, meta: { payload } }, // prettier-ignore
      },
    },
  }
})

export const clearSpeedContent = (payload) => ({
  type: t.CLEAR_SPEED_CONTENT,
  payload,
})
