import * as t from './types'

export const clearAnswers = (payload) => ({
  type: t.CLEAR_ANSWERS,
  payload,
})

export const refreshAnswers = (payload) => ({
  type: t.REFRESH_ANSWERS,
  payload,
  meta: {
    offline: {
      effect: {
        path: 'admin/answers',
        method: 'POST',
        limit: payload.answerIds.length,
      },
      commit: { type: t.REFRESH_ANSWERS_SUCCESS },
      rollback: { type: t.REFRESH_ANSWERS_ERROR },
    },
  },
})

export const refreshBadwordscore = ({
  language,
  badwordLevel,
  sexualLevel,
  str,
}) => ({
  type: t.REFRESH_BADWORDSCORE,
  payload: { language, badwordLevel, sexualLevel, str },
  meta: {
    offline: {
      effect: {
        path: 'admin/special/badwordscore',
        method: 'POST',
      },
      commit: {
        type: t.REFRESH_BADWORDSCORE_SUCCESS,
      },
      rollback: { type: t.REFRESH_BADWORDSCORE_ERROR },
    },
  },
})

export const rerollExperiments = (payload) => ({
  type: t.REROLL_EXPERIMENTS,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/experiments/rerollcountry', method: 'POST' },
      commit: { type: t.REROLL_EXPERIMENTS_SUCCESS },
      rollback: { type: t.REROLL_EXPERIMENTS_ERROR },
    },
  },
})
