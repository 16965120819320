export const name = 'tools'

export const CLEAR_ANSWERS = `${name}/CLEAR_ANSWERS`

export const REFRESH_ANSWERS = `${name}/REFRESH_ANSWERS`
export const REFRESH_ANSWERS_ERROR = `${name}/REFRESH_ANSWERS_ERROR`
export const REFRESH_ANSWERS_SUCCESS = `${name}/REFRESH_ANSWERS_SUCCESS`

export const REFRESH_BADWORDSCORE = `${name}/REFRESH_BADWORDSCORE`
export const REFRESH_BADWORDSCORE_ERROR = `${name}/REFRESH_BADWORDSCORE_ERROR`
export const REFRESH_BADWORDSCORE_SUCCESS = `${name}/REFRESH_BADWORDSCORE_SUCCESS`

export const REROLL_EXPERIMENTS = `${name}/REROLL_EXPERIMENTS`
export const REROLL_EXPERIMENTS_ERROR = `${name}/REROLL_EXPERIMENTS_ERROR`
export const REROLL_EXPERIMENTS_SUCCESS = `${name}/REROLL_EXPERIMENTS_SUCCESS`
