import { TableProps } from 'antd'
import React from 'react'

export const useTableState = () => {
  const [table, setTableState] = React.useState({
    filters: {},
    pagination: {},
    sorter: {},
  })

  const onChange = React.useCallback(
    ((pagination, filters, sorter) => {
      let nextSorter = sorter

      if (Array.isArray(sorter)) {
        /**
         * The initial sorter might be an array of filters because they are set like that for default. We want the
         * behavior though that when the user clicks on a column, that only this one is sorted and not all of them.
         * So we take the last entry from the object which is indicated by the highest key.
         */
        const lastSorterEntryId = Math.max(...Object.keys(sorter).map(Number))
        const lastSorterEntry = sorter[lastSorterEntryId]

        nextSorter = lastSorterEntry
      }

      setTableState({ pagination, filters, sorter: nextSorter })
    }) as TableProps<any>['onChange'],
    []
  )

  return {
    ...table,
    setTableState,
    onChange,
  }
}
