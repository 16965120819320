import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { Button } from 'antd'
import React, { useMemo } from 'react'
import * as ReactRedux from 'react-redux'
import {
  Box,
  PopupModal,
  TableEndlessScroll,
  View,
  _,
  styleSheets,
} from '../../common'
import { editGroup } from '../actionsV2'
import { getHeaderMode } from '../selectorsV2'
import { ModalMoveGroupToOtherTopic } from './ModalMoveGroupToOtherTopic'
import { SharedColumns, Stat, useColumnSearchProps } from './SharedColumns'

export const ArtificialTellTopicGroupsTable = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const searchProps = useColumnSearchProps('name')

  const onPopupEvent = React.useCallback(
    ({ index, group }) => {
      const setInactiveForLang = () => {
        dispatch(
          editGroup({
            id: group.id,
            status: ARTIFICIAL_TELL_STATUS.INACTIVE,
            languageForChangingStatus: langDetectObjectsByType1[props.language],
          })
        )
      }

      const setInactiveForAllLangs = () => {
        dispatch(
          editGroup({
            id: group.id,
            status: ARTIFICIAL_TELL_STATUS.INACTIVE,
            languageForChangingStatus: 'ALL',
          })
        )
      }

      const deleteGroup = () => {
        if (group.status === ARTIFICIAL_TELL_STATUS.INACTIVE) {
          dispatch(
            editGroup({
              id: group.id,
              status: ARTIFICIAL_TELL_STATUS.DELETED,
              languageForChangingStatus: 'ALL',
            })
          )
        }
      }

      switch (index) {
        case 0:
          return ModalMoveGroupToOtherTopic.show({
            topicId: props.topicId,
            group,
          })
        case 1:
          return setInactiveForLang()
        case 2:
          return setInactiveForAllLangs()
        case 3:
          return deleteGroup()
        default:
          return undefined
      }
    },
    [dispatch, props.language, props.topicId]
  )

  const getMenuActions = ({ group }) => {
    return [
      'Move group to other topic',
      'Set inactive for selected language',
      'Set inactive for all languages',
      group.status === ARTIFICIAL_TELL_STATUS.INACTIVE &&
        'Delete for all languages',
    ].filter(Boolean)
  }

  const columns = useMemo(
    () =>
      props.columns ?? [
        {
          ...SharedColumns.id,
          sorter: { multiple: 1, compare: SharedColumns.id.sorter },
          /**
           * This allows to pass specific ids that should be filtered for
           * Requires onPressResetFilter to be passed as well
           */
          ...(props.isDataSourceFiltered
            ? {
                filtered: true,
                filters: [],
                filterDropdownOpen: false,
                onFilterDropdownOpenChange: (open) => {
                  props.onPressResetFilter?.(open)
                },
              }
            : {}),
        },
        SharedColumns.status,
        SharedColumns.groupDepth,
        SharedColumns.groupType,
        {
          title: 'Question',
          dataIndex: 'id',
          key: 'name',
          width: 200,
          render: (id, group) => <View>{group.name}</View>,
          ...searchProps,
          sorter: (a, b) => {
            const textA = a.name?.toLowerCase()
            const textB = b.name?.toLowerCase()

            if (textA < textB) {
              return -1
            }

            if (textA > textB) {
              return 1
            }

            return 0
          },
        },
        SharedColumns.groupTotalSent,
        {
          title: 'Answer Rate',
          dataIndex: 'id',
          key: 'answerRate',
          width: 80,
          sorter: (a, b) =>
            a.analytics.answerRate.amount - b.analytics.answerRate.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                isPercentage
                value={group.analytics.answerRate.amount}
                change={group.analytics.answerRate.change}
              />
            )
          },
        },
        {
          title: 'Median Length',
          dataIndex: 'id',
          key: 'p50Length',
          width: 80,
          sorter: (a, b) =>
            a.analytics.p50Length.amount - b.analytics.p50Length.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                value={group.analytics.p50Length.amount}
                change={group.analytics.p50Length.change}
              />
            )
          },
        },
        {
          title: 'Share Rate',
          dataIndex: 'id',
          key: 'shareRate',
          width: 80,
          sorter: (a, b) =>
            a.analytics.shareRate.amount - b.analytics.shareRate.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                isPercentage
                value={group.analytics.shareRate.amount}
                change={group.analytics.shareRate.change}
              />
            )
          },
        },
        {
          title: 'Reuse Days',
          dataIndex: 'id',
          key: 'reaskInDays',
          width: 80,
          filterMultiple: false,
          filters: [
            {
              text: 'Greater than 0',
              value: 1,
            },
          ],
          onFilter: (value, group) => {
            return value === 1 ? group.reaskInDays > 0 : true
          },
          onHeaderCell: () => ({
            onClick: () => {
              props.setTableState((tableState) => ({
                ...tableState,
                filters: {
                  ...tableState.filters,
                  reaskInDays: Array.isArray(tableState.filters.reaskInDays)
                    ? undefined
                    : [1],
                },
              }))
            },
          }),
          render: (id, group) => (
            <View style={styleSheets.center}>{group.reaskInDays}</View>
          ),
        },
        {
          title: 'Vars',
          dataIndex: 'id',
          key: 'activeVariancesCountTotal',
          width: 80,
          sorter: (a, b) =>
            a.activeVariancesCount.total - b.activeVariancesCount.total,
          filterMultiple: false,
          filters: [
            {
              text: 'Only Female Variances',
              value: 1,
            },
            {
              text: 'Only Male Variances',
              value: 2,
            },
            {
              text: 'No Both Variances',
              value: 3,
            },
          ],
          onFilter: (value, group) => {
            switch (value) {
              case 1:
                return (
                  group.activeVariancesCount.both === 0 &&
                  group.activeVariancesCount.male === 0
                )
              case 2:
                return (
                  group.activeVariancesCount.both === 0 &&
                  group.activeVariancesCount.female === 0
                )
              case 3:
                return group.activeVariancesCount.both === 0
              default:
                return true
            }
          },
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <View style={styleSheets.center}>
                {group.activeVariancesCount.total}
              </View>
            )
          },
        },
        SharedColumns.groupSenderHint,
        SharedColumns.lastUpdatedAt,
        {
          title: 'Actions',
          dataIndex: 'id',
          key: 'actions',
          width: 80,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Box alignItems="center" justifyContent="center" transparent>
                <Button
                  onClick={(event) => {
                    event.stopPropagation()

                    ModalMoveGroupToOtherTopic.show({
                      topicId: props.topicId,
                      group,
                    })
                  }}
                  size="small"
                  style={{ width: 55 }}>
                  Move
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation()

                    PopupModal.show({
                      actions: getMenuActions({ group }),
                      onPress: (index) => onPopupEvent({ index, group }),
                    })
                  }}
                  size="small"
                  style={{ width: 55 }}>
                  More
                </Button>
              </Box>
            )
          },
        },
      ],
    [
      props.columns,
      onPopupEvent,
      props.isDataSourceFiltered,
      props.onPressResetFilter,
    ]
  )

  const onRow = React.useCallback(
    (group) => {
      const routeProps = _.openRouteProps(
        `/artificialtells_v2/${props.language ?? 'en'}/group/${group.id}${
          headerMode ? `?headerMode=${headerMode}` : ''
        }`
      )

      return { onClick: routeProps.onPress, onAuxClick: routeProps.onAuxClick }
    },
    [props.language, headerMode]
  )

  return <TableEndlessScroll {...props} columns={columns} onRow={onRow} />
}
