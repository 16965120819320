import { colors } from '@tellonym/core/common/colorSystem'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { Spin } from 'antd'
import { Style } from 'radium'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { _, Box, hooks, TableEndlessScroll, Text, View } from '../../common'
import { TagBox } from '../../common/components/TagBox'
import { languageEnumAsString } from '../../common/helpers'
import { useValidationQuery } from '../queries'
import { getLanguage } from '../selectorsV2'
import { ArtificialTellStatusTag } from './ArtificialTellStatusTag'
import { SelectorsLanguage } from './SelectorsLanguage'
import { SharedColumns, useColumnSearchProps } from './SharedColumns'

const antdTableOverrides = {
  '.ant-table-tbody > tr > td': {
    paddingTop: 4,
    paddingBottom: 4,
  },
}

/**
 * This is manually measured (with chrome debug element inspection) and necessary to prevent the page from
 * being scrollable. We don't want that because the table is already scrollable.
 */
const HEADER_HEIGHT = 80

const Header = ({ validationData }) => {
  // We abuse our languageStatusList in the lang selector to indicate which langs have active validation groups
  const languageStatusList = validationData
    ? Object.keys(validationData).map((lang) => ({
        language: lang,
        status:
          validationData[lang].activeGroups.ids.length > 0 ||
          validationData[lang].scheduledValidation.ids.length > 0
            ? ARTIFICIAL_TELL_STATUS.VALIDATION
            : undefined,
      }))
    : []

  return (
    <Box padding={24} width="100%" flexDirection="row">
      <Box flex={1} paddingHorizontal={0} flexDirection="row">
        {validationData
          ? Object.keys(validationData).map((lang) => {
              const { activeGroups, scheduledValidation } = validationData[lang]

              if (
                activeGroups.ids.length === 0 &&
                scheduledValidation.ids.length === 0
              ) {
                return null
              }

              return (
                <Text style={{ marginRight: 8 }}>
                  {`${languageEnumAsString[lang].toUpperCase()}:`}{' '}
                  <Text
                    color={
                      colors.grey[6]
                    }>{`${activeGroups.ids.length} Val. | ${scheduledValidation.ids.length} Wait`}</Text>
                </Text>
              )
            })
          : null}
      </Box>
      <Box alignItems="flex-end" transparent>
        <SelectorsLanguage languageStatusList={languageStatusList} />
      </Box>
    </Box>
  )
}

const ArtificialTellValidationGroupsTable = (props) => {
  const searchProps = useColumnSearchProps('name')

  const columns = React.useMemo(
    () => [
      {
        ...SharedColumns.id,
        sorter: { multiple: 1, compare: SharedColumns.id.sorter },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 80,
        render: (status) =>
          status === 'Scheduled' ? (
            <TagBox backgroundColor={colors.grey[1]} text="Scheduled" />
          ) : (
            <ArtificialTellStatusTag status={status} />
          ),
      },
      SharedColumns.groupDepth,
      SharedColumns.groupType,
      {
        title: 'Question',
        dataIndex: 'id',
        key: 'name',
        width: '40%',
        render: (id, group) => <View>{group.name}</View>,
        ...searchProps,
        sorter: (a, b) => {
          const textA = a.name?.toLowerCase()
          const textB = b.name?.toLowerCase()

          if (textA < textB) {
            return -1
          }

          if (textA > textB) {
            return 1
          }

          return 0
        },
      },
      SharedColumns.groupTotalSent,
      SharedColumns.groupSenderHint,
      SharedColumns.lastUpdatedAt,
    ],
    [props.columns]
  )

  const onRow = React.useCallback(
    (group) => {
      const routeProps = _.openRouteProps(
        `/artificialtells_v2/${props.language ?? 'en'}/group/${group.id}`
      )

      return { onClick: routeProps.onPress, onAuxClick: routeProps.onAuxClick }
    },
    [props.language]
  )

  return <TableEndlessScroll {...props} columns={columns} onRow={onRow} />
}

export const PageArtificialTellsValidation = () => {
  const language = ReactRedux.useSelector(getLanguage)

  const table = hooks.useTableState()

  const { data: validationData, isLoading } = useValidationQuery()

  const currentLanguageData =
    validationData?.[langDetectObjectsByType1[language]]
  const dataSource = [
    ...(currentLanguageData?.activeGroups?.data ?? []).map((group) => ({
      ...group,
      status: ARTIFICIAL_TELL_STATUS.VALIDATION,
    })),
    ...(currentLanguageData?.scheduledValidation?.data ?? []).map((group) => ({
      ...group,
      status: 'Scheduled',
    })),
  ]

  return (
    <Box flex={1} flexGrow={1}>
      <Style rules={antdTableOverrides} />
      <Header validationData={validationData} language={language} />

      <Spin spinning={isLoading}>
        <Box flex={1} flexGrow={1} alignItems="center">
          <Box maxWidth={2100}>
            <ArtificialTellValidationGroupsTable
              {...table}
              dataSource={dataSource}
              language={language}
              offset={HEADER_HEIGHT}
            />
          </Box>
        </Box>
      </Spin>
    </Box>
  )
}
