import { Button, Checkbox, Input } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { AnswerItem, Box, ScrollView } from '../../common'
import * as hooks from '../../common/hooks'
import { clearAnswers, refreshAnswers } from '../actions'
import { getAnswers, getIsRefreshingAnswers } from '../selectors'
import { parseInput, sanitizeInput } from './PageProfileVisualiser'

const defaultQueryParams = {
  answerIds: '',
  shouldShowOnlyActive: 'true',
}

export const PageAnswersVisualiser = () => {
  const prevTextInput = React.useRef('')
  const dispatch = ReactRedux.useDispatch()
  const [params, setQueryParams] = hooks.useQueryParams(defaultQueryParams, 300)
  const [textInput, setTextInput] = React.useState(params.answerIds)

  const [shouldShowOnlyActive, setShouldShowOnlyActive] = React.useState(
    params.shouldShowOnlyActive === 'true'
  )

  const answers = ReactRedux.useSelector(getAnswers)
  const isRefreshingAnswers = ReactRedux.useSelector(getIsRefreshingAnswers)

  const onPressRefresh = React.useCallback(() => {
    if (textInput === '') {
      return
    }

    const answerIds = parseInput(textInput)

    if (answerIds.length) {
      dispatch(refreshAnswers({ answerIds, shouldShowOnlyActive }))
    }
  }, [shouldShowOnlyActive, textInput])

  const onChangeInput = (e) => {
    setTextInput(e.target.value)
  }

  React.useEffect(() => {
    const isBulkInsert =
      Math.abs((prevTextInput.current?.length ?? 0) - textInput.length) > 3

    if (isBulkInsert) {
      onPressRefresh()
    }

    if (prevTextInput.current !== textInput) {
      prevTextInput.current = textInput

      setQueryParams({ answerIds: sanitizeInput(textInput) })
    }
  }, [onPressRefresh, setQueryParams, textInput])

  React.useEffect(() => {
    setQueryParams({ shouldShowOnlyActive })
  }, [shouldShowOnlyActive, setQueryParams])

  React.useEffect(() => {
    const answerIds = parseInput(textInput)

    if (answerIds.length) {
      dispatch(
        refreshAnswers({
          answerIds: parseInput(textInput),
          shouldShowOnlyActive,
        })
      )
    }

    return () => {
      dispatch(clearAnswers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box flexDirection="row" height="100%" paddingLeft={16}>
      <Box height="80%" paddingTop={16} gap={8}>
        <Input.TextArea
          allowClear
          onChange={onChangeInput}
          placeholder="Insert answer ids to be visualised. Can be separated by comma, space or line breaks."
          rows={8}
          value={textInput}
          style={{ height: '100%' }}
        />

        <Box paddingHorizontal={8} alignItems="center">
          <Checkbox
            onChange={(e) => {
              setShouldShowOnlyActive(e.target.checked)
            }}
            checked={shouldShowOnlyActive}>
            Only active posts
          </Checkbox>
        </Box>

        <Button
          type="primary"
          onClick={onPressRefresh}
          loading={isRefreshingAnswers}>
          Refresh
        </Button>
      </Box>

      <Box flex={1}>
        <ScrollView style={{ width: 800, paddingTop: 16, paddingLeft: 8 }}>
          {answers.map((answer) => (
            <Box
              key={answer.id}
              style={{
                transform: 'scale(0.7)',
                transformOrigin: '0 0',
                width: `${100 / 0.7}%`,
                height: `${100 / 0.7}%`,
                marginBottom: '-5%',
              }}>
              <AnswerItem item={answer} isMinimal />
            </Box>
          ))}
        </ScrollView>
      </Box>
    </Box>
  )
}
