import { AppstoreOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Input, Radio, Spin, Typography, message } from 'antd'
import { assoc, compose } from 'ramda'
import React from 'react'
import { Avatar, Box, Link, Text, View, colors } from '../../common'
import { IconVerified } from '../../common/components/IconVerified'
import { ProfileCardUser } from '../../common/components/ProfileCardUser'
import * as hooks from '../../common/hooks'
import { useProfilesMutation } from '../../profile/queries'

const styles = {
  textarea: { maxWidth: '60%' },
  typeCounterText: { marginTop: 2, marginLeft: 12 },
}

export const parseInput = (string) => {
  try {
    const regex = /[\n, ]+/

    const numberStrings = string
      .replace(/"/gm, '')
      .split(regex)
      .filter(Boolean)
      .filter((s) => !isNaN(s)) // eslint-disable-line no-restricted-globals

    const numbers = numberStrings.map(Number)

    return numbers ?? []
  } catch (e) {
    console.error(e)

    message.error(e.message)

    return []
  }
}

export const stringifyInput = (array) => array?.join(', ')

export const sanitizeInput = compose(stringifyInput, parseInput)

const defaultQueryParams = {
  userIds: '',
}

const VIEW_STATE = {
  AVATARS: 'AVATARS',
  GRID: 'GRID',
}

const AvatarGroupStraight = ({
  containerStyle,
  user,
  creatorOnPressAvatar,
}) => {
  return (
    <Box style={containerStyle}>
      <Box
        borderWidth={1}
        borderColor={colors.grey[1]}
        borderRadius={8}
        flexDirection="row"
        overflow="hidden">
        {typeof user?.avatars !== 'undefined'
          ? [
              user.avatars.find(({ position }) => position === 1),
              user.avatars.find(({ position }) => position === 0),
              user.avatars.find(({ position }) => position === 2),
            ].map((avatar, index) => {
              return (
                <Box
                  key={avatar?.avatarFileName ?? index}
                  alignItems="center"
                  marginLeft={2}
                  marginRight={2}
                  onPress={creatorOnPressAvatar?.(avatar?.avatarFileName)}>
                  <Avatar
                    rectangle
                    user={assoc('avatarFileName', avatar?.avatarFileName, user)}
                    size={110}
                  />
                </Box>
              )
            })
          : null}
      </Box>
      <Box width="100%" marginTop={8} height={20}>
        <Box
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          marginHorizontal={user.isVerified ? 5 : 0}>
          <Text
            color={colors.black[1]}
            type="small"
            style={{
              fontWeight: '700',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>
            {user.displayName}
          </Text>
          {user.isVerified && (
            <Box marginLeft={2}>
              <IconVerified size={12} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export const PageProfileVisualiser = () => {
  const containerStyle = hooks.usePageContainerStyle()
  const prevTextInput = React.useRef('')
  const [params, setQueryParams] = hooks.useQueryParams(defaultQueryParams, 300)
  const [textInput, setTextInput] = React.useState(params.userIds)
  const [viewState, setViewState] = React.useState(VIEW_STATE.AVATARS)

  const { isLoading, mutate, data } = useProfilesMutation()

  const onPressRefresh = React.useCallback(() => {
    if (textInput === '') {
      return
    }

    const userIds = parseInput(textInput)

    mutate({ userIds })
  }, [mutate, textInput])

  const onChangeInput = (e) => {
    setTextInput(e.target.value)
  }

  React.useEffect(() => {
    const isBulkInsert =
      Math.abs((prevTextInput.current?.length ?? 0) - textInput.length) > 3

    if (isBulkInsert) {
      onPressRefresh()
    }

    if (prevTextInput.current !== textInput) {
      prevTextInput.current = textInput

      setQueryParams({ userIds: sanitizeInput(textInput) })
    }
  }, [onPressRefresh, setQueryParams, textInput])

  const inputIdsCount = React.useMemo(
    () =>
      params.userIds === '' ? '-' : parseInput(params.userIds)?.length ?? 0,
    [params.userIds]
  )

  const Component = React.useMemo(
    () =>
      viewState === VIEW_STATE.AVATARS ? ProfileCardUser : AvatarGroupStraight,
    [viewState]
  )

  return (
    <View style={containerStyle}>
      <Box padding={16} backgroundColor={colors.background}>
        <Typography.Title>Profile Visualiser</Typography.Title>
        <Box marginTop={24} flexDirection="row">
          <Input.TextArea
            allowClear
            onChange={onChangeInput}
            placeholder="Insert user ids to be visualised. Can be separated by comma, space or line breaks."
            rows={8}
            value={textInput}
            style={styles.textarea}
          />
          <Box justifyContent="flex-end" marginLeft={8}>
            <Button type="primary" onClick={onPressRefresh}>
              Refresh
            </Button>
          </Box>
        </Box>

        <Box marginTop={48}>
          <Spin size="large" spinning={isLoading}>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={8}>
              <Box flexDirection="row">
                <Typography.Title level={4}>
                  Results {`(${data?.users?.length ?? '-'} / ${inputIdsCount})`}
                </Typography.Title>
                <Text style={styles.typeCounterText}>{`banned: ${
                  data?.bannedCount ?? '-'
                }`}</Text>
                <Text style={styles.typeCounterText}>{`deleted: ${
                  data?.deletedCount ?? '-'
                }`}</Text>
              </Box>
              <Box flexDirection="row" paddingHorizontal={16}>
                <Radio.Group
                  size="large"
                  optionType="button"
                  options={[
                    { label: <TeamOutlined />, value: VIEW_STATE.AVATARS },
                    { label: <AppstoreOutlined />, value: VIEW_STATE.GRID },
                  ]}
                  onChange={(e) => setViewState(e.target.value)}
                  value={viewState}
                />
              </Box>
            </Box>
            <Box flexDirection="row" flexWrap="wrap">
              {data?.users?.map?.((profile) => (
                <Box key={profile.id} marginRight={24} marginBottom={24}>
                  <Link to={`/user/${profile.id}`}>
                    <Component user={profile} />
                  </Link>
                </Box>
              ))}
            </Box>
          </Spin>
        </Box>
      </Box>
    </View>
  )
}
