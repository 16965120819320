import { nameV2 as name } from './constants'

export const getDataMode = (state) => state[name].dataMode

export const getHeaderMode = (state) => state[name].headerMode

export const getLanguage = (state) => state[name].language

export const getIsRefreshingTopics = (state) => state[name].topics.isRefreshing

export const getIsRefreshingTopicDetails = (state) =>
  state[name].topicDetails.isRefreshing

export const getIsRefreshingGroupDetails = (state) =>
  state[name].groupDetails.isRefreshing

export const getIsRefreshingTodos = (state) => state[name].isRefreshingTodos

export const getIsRefreshingVarianceAnalytics = (state) =>
  state[name].varianceAnalytics.isRefreshing

export const getShouldShowDeletedVariances = (state) =>
  state[name].groupDetails.shouldShowDeletedVariances

/**
 * Speed Translations
 */
export const getIsRefreshingSpeedTranslations = (state) =>
  state[name].isRefreshingSpeedTranslations

export const getIsRefreshingSpeedTranslationsByGender = (state) =>
  state[name].isRefreshingSpeedTranslationsByGender

export const getSpeedTranslationsByGroupId = (state) =>
  state[name].speedTranslationsByGroupId

/**
 * Speed Content
 */
export const getIsRefreshingSpeedContent = (state) =>
  state[name].isRefreshingSpeedContent

export const getIsRefreshingSpeedContentParams = (state) =>
  state[name].isRefreshingSpeedContentParams

export const getIsSavingGroups = (state) => state[name].isSavingGroups

export const getSpeedContentByTopicId = (state) =>
  state[name].speedContentByTopicId

export const getSpeedContentParamsByTopicId = (state) =>
  state[name].speedContentParamsByTopicId
