import { DeleteOutlined } from '@ant-design/icons'
import { headerModes } from '@tellonym/core/artificialTells/constants'
import { Breadcrumb, Button, Segmented, Tooltip, Typography } from 'antd'
import moment from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router'
import { Box } from '../../common/components/Box'
import { history } from '../../common/history'
import { theme } from '../../common/styles/theme'
import { setHeaderMode } from '../actionsV2'
import { useDeleteAllVariancesMutation } from '../queries'
import { getHeaderMode } from '../selectorsV2'
import { ArtificialTellStatusTag } from './ArtificialTellStatusTag'
import { ButtonBack } from './ButtonBack'
import { SelectorsLanguage } from './SelectorsLanguage'

export const Breadcrumbs = ({ group, topic, language }) => {
  return (
    <Box flexDirection="row" transparent alignItems="center">
      <ButtonBack
        onPress={() => {
          history.goBack()
        }}
        style={{ marginRight: 12, width: 30, height: 30 }}
      />

      <Breadcrumb>
        <Breadcrumb.Item key="Topics">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              history.push({
                pathname: `/artificialtells_v2/${language}/topics/list`,
              })
            }}>
            Topics
          </a>
        </Breadcrumb.Item>

        {topic ? (
          <Breadcrumb.Item key="TopicId">{topic.id}</Breadcrumb.Item>
        ) : (
          <>
            <Breadcrumb.Item key="TopicId">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  history.push({
                    pathname: `/artificialtells_v2/${language}/topic/${group.topic?.id}`,
                  })
                }}>
                {group.topic?.name}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              key="GroupId"
              className={`${group.name}_${language}`}>
              {group.id}
            </Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>
    </Box>
  )
}

/**
 * either topic or group is defined
 */
export const BreadcrumbHeader = ({
  hasGraph,
  hasTranslations,
  group,
  topic,
  language,
}) => {
  const dispatch = ReactRedux.useDispatch()
  const match = ReactRouter.useRouteMatch()

  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const { mutate: fetchDeleteAllVariances, isLoading } =
    useDeleteAllVariancesMutation({
      groupId: group?.id,
      language: parseInt(match.params.groupId, 10),
      ids: group?.variances.map((variance) => variance.id),
    })

  const onChangeHeaderMode = (value) => {
    dispatch(setHeaderMode(value))
  }

  const onPressDeleteAllVariances = () => {
    fetchDeleteAllVariances()
  }

  const onPressShowAnswers = (e) => {
    const isActionKeyPressed = e.metaKey || e.ctrlKey
    const route = `/artificialtells_v2/${language}/group/${group.id}/answers`

    if (isActionKeyPressed) {
      window.open(route, '_blank')
    } else {
      history.push(route)
    }
  }

  return (
    <Box flexDirection="row" justifyContent="space-between" transparent>
      <Box transparent flex={3}>
        <Box flexDirection="row" transparent alignItems="center">
          <Breadcrumbs group={group} topic={topic} language={language} />

          <Tooltip
            title={
              topic
                ? undefined
                : [
                    `Active ${group.activeVarianceTotals?.total ?? 0}`,
                    `Male ${group.activeVarianceTotals?.male ?? 0}`,
                    `Female ${group.activeVarianceTotals?.female ?? 0}`,
                    `Both ${group.activeVarianceTotals?.both ?? 0}`,
                  ].join(' - ')
            }>
            <Box marginLeft={12} transparent>
              <ArtificialTellStatusTag
                status={group?.status || topic?.status}
              />
            </Box>
          </Tooltip>

          {Boolean(topic) === false && (
            <Tooltip title="Delete all variances in this group.">
              <Button
                loading={isLoading}
                size="small"
                danger
                onClick={onPressDeleteAllVariances}
                style={{ marginLeft: 12 }}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
          )}

          {typeof group?.id !== 'undefined' && (
            <Button
              onClick={onPressShowAnswers}
              size="small"
              style={{ marginLeft: 12, width: 150 }}>
              Show Answers
            </Button>
          )}
        </Box>
      </Box>

      <Box transparent alignItems="center" paddingVertical={4} flex={1}>
        <Segmented
          value={headerMode}
          options={[
            { label: 'Edit', value: headerModes.EDIT },
            { label: 'Stats', value: headerModes.STATS },
            hasTranslations && {
              label: 'Translate',
              value: headerModes.TRANSLATE,
            },
            hasGraph && { label: 'Graph', value: headerModes.GRAPH },
          ].filter(Boolean)}
          onChange={onChangeHeaderMode}
          size="small"
        />
      </Box>

      <Box transparent>
        <SelectorsLanguage
          languageStatusList={group?.languages ?? topic?.languages}
        />

        {group?.lastUpdatedAt || topic?.lastUpdatedAt ? (
          <Box transparent alignItems="flex-end">
            <Typography.Text
              style={{
                color: theme.colors.antdBreadcrumbInactive,
              }}>{`last edited ${moment(
              group?.lastUpdatedAt ?? topic?.lastUpdatedAt
            ).format('DD.MM HH:mm[h]')}`}</Typography.Text>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}
