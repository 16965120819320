import { Typography } from 'antd'
import React from 'react'
import { Box, hooks } from '../../common'

const Comp = () => 'text'

export const PageArtificialTellsDashboard = () => {
  const containerStyle = hooks.usePageContainerStyle()

  return (
    <Box style={containerStyle}>
      <Box padding={32}>
        <Typography.Title>Dashboard</Typography.Title>

        <Typography.Title level={4}>General</Typography.Title>
        <Box flexDirection="row" flexWrap="wrap" />
      </Box>
    </Box>
  )
}
