import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
} from '@tellonym/enums/lib/Tell'
import { supportedArTellLanguages } from '@tellonym/enums/lib/TellArtificial'
import { languageEnumAsString } from '../common/helpers'

export const name = 'artificialTells'
export const nameV2 = 'artificialTellsV2'

export const supportedLanguages = supportedArTellLanguages.map(
  (i) => languageEnumAsString[i]
)

/**
 * If you want to add a new order type you just have to add it in
 * - orderTypes constant
 * - groupOrderTypesList (this is the order of orderTypes)
 * - getSortedItemsForType in GroupFilter.js (your sorting function)
 */
export const orderTypes = {
  ANSWER_RATE: 'Order By Answer Rate',
  LONG_ANSWERS: 'Order By Long Answers',
  SHORT_ANSWERS: 'Order By Short Answers',
  DATE: 'Date Restricted',
  DESC: 'Reverse Order',
  DRAFTS: 'Has Open Drafts',
  ORDER_BY_REVIEW_STATUS: 'Order By Review Status',
  ONLY_NOT_REVIEWED: 'Only Not Reviewed',
}

export const groupOrderTypesList = [
  orderTypes.ANSWER_RATE,
  orderTypes.DESC,
  orderTypes.DATE,
  orderTypes.DRAFTS,
  orderTypes.ONLY_NOT_REVIEWED,
  orderTypes.ORDER_BY_REVIEW_STATUS,
  orderTypes.LONG_ANSWERS,
  orderTypes.SHORT_ANSWERS,
]

export const typeOrder = {
  [ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC]: 0,
  [ARTIFICIAL_TELL_GROUP_TYPES.OPEN]: 1,
  [ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]: 2,
  [ARTIFICIAL_TELL_GROUP_TYPES.SHARE]: 3,
  [ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT]: 4,
}

export const depthOrder = {
  [ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]: 0,
  [ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]: 1,
}
