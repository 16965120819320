import { colors } from '@tellonym/core/common/colorSystem'
import { Button, Card, Typography } from 'antd'
import React from 'react'
import { Box, _ } from '../../common'
import * as hooks from '../../common/hooks'
import {
  useContentMeetingSheetMutation,
  useProductMeetingSheetMutation,
} from '../queries'

const items = [
  {
    title: 'Experiments Reroll',
    description:
      'Reroll the allocation of an experiment. This assigns a new experiment to everyone in the specified country.',
    route: '/tools/experiments',
  },
  {
    title: 'Answers Visualiser',
    description: 'Get a quick overview of answers from a list of answer ids.',
    route: '/tools/answersvisualiser',
  },
  {
    title: 'Profile Visualiser',
    description: 'Get a quick overview of images from a list of user ids.',
    route: '/tools/profilevisualiser',
  },
  {
    title: 'Artificial Tell Spreadsheet Parser',
    description:
      'Paste artificial tell content from a spreadsheet to quickly add it to a topic.',
    route: '/artificialtells_v2/spreadsheetparser',
  },
  {
    title: 'TikTok Video Generator',
    description: 'Generate a TikTok video based on content you provide.',
    route: '/tools/generatetiktokvideo',
  },
  {
    title: 'TikTok Stats overview',
    description: 'See TikTok stats for a user.',
    route: '/tools/tiktokstats',
  },
  {
    title: 'CH CSV Download',
    description: 'Download CH stats in CSV format easily.',
    route: '/stats/csv',
  },
  {
    title: 'Upload Store Stats',
    description:
      'Upload statistics from each store to track downloads internally.',
    route: '/stats/upload',
  },
]

const itemsModeration = [
  {
    title: 'Badwordscore',
    description:
      'See if content would be filtered by our different levels of safety.',
    route: '/tools/badwordscore',
  },
  {
    title: 'Ban Candidates',
    description:
      'Users we identified as potentially harmful. Should be checked and decided on.',
    route: '/ban/candidates',
  },
  {
    title: 'Banned Devices',
    description: 'Devices we banned from using Tellonym.',
    route: '/ban/devices',
  },
  {
    title: 'Content Blacklist',
    description:
      'List of words that are harmful. For Tells they get blocked, for about me and social links the profile gets shadow banned.',
    route: '/blacklist',
  },
  {
    title: 'Last Blocked',
    description: 'See what has been blocked recently and the reason for it.',
    route: '/lastblocked',
  },
  {
    title: 'Spam Detection',
    description:
      'List of profiles that we identified as potential spam accounts.',
    route: '/spam',
  },
]

const styles = {
  cardStyle: {
    width: 300,
    height: 195,
    marginRight: 32,
    marginBottom: 32,
  },
  actionButton: {
    marginBottom: 12,
    marginRight: 12,
  },
}

const Actions = () => {
  const {
    mutate: mutateContentMeetingSheet,
    isLoading: isLoadingContentMeeting,
  } = useContentMeetingSheetMutation()

  const {
    mutate: mutateProductMeetingSheet,
    isLoading: isLoadingProductMeeting,
  } = useProductMeetingSheetMutation()

  const onPressContentMeetingSheet = () => {
    mutateContentMeetingSheet()
  }

  const onPressProductMeetingSheet = () => {
    mutateProductMeetingSheet()
  }

  return (
    <Box alignItems="flex-start" marginBottom={32}>
      <Button
        loading={isLoadingContentMeeting}
        onClick={onPressContentMeetingSheet}
        style={styles.actionButton}>
        Content: Create Weekly Data Sheet
      </Button>

      <Button
        loading={isLoadingProductMeeting}
        onClick={onPressProductMeetingSheet}
        style={styles.actionButton}>
        Product: Create Weekly Data Sheet
      </Button>
    </Box>
  )
}

export const Index = () => {
  const containerStyle = hooks.usePageContainerStyle()

  return (
    <Box style={containerStyle}>
      <Box padding={32} backgroundColor={colors.background}>
        <Typography.Title>Dashboard</Typography.Title>

        <Typography.Title level={4}>Actions</Typography.Title>
        <Actions />

        <Typography.Title level={4}>General</Typography.Title>
        <Box flexDirection="row" flexWrap="wrap">
          {items.map(({ title, description, route }) => (
            <Box {..._.openRouteProps(route)}>
              <Card hoverable title={title} style={styles.cardStyle}>
                {description}
              </Card>
            </Box>
          ))}
        </Box>

        <Typography.Title level={4}>Moderation</Typography.Title>
        <Box flexDirection="row" flexWrap="wrap">
          {itemsModeration.map(({ title, description, route }) => (
            <Box {..._.openRouteProps(route)}>
              <Card hoverable title={title} style={styles.cardStyle}>
                {description}
              </Card>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
