import { useQuery } from '@tanstack/react-query'
import { buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { store } from './store'

let reactQueryClient

export const setQueryClient = (client) => {
  reactQueryClient = client
}

export const queryClient = {
  fetchQuery: async (props) => reactQueryClient?.fetchQuery(props) ?? {},
  invalidateQueries: async (props) =>
    reactQueryClient?.invalidateQueries(props) ?? {},
}

const noop = () => {}

/**
 * Helper for queries that shortens the boilerplate code to do the request
 * @param {String} generatorProps.path the path to the endpoint
 * @param {String} generatorProps.method POST or GET, defaults to GET in buildFetch
 * @param {Function} generatorProps.buildPayload create the payload for the request, gets the props as arguments, defaults to identity
 * @returns {Function} async function that does the request
 */
export const buildRequest =
  (generatorProps) =>
  async (...props) => {
    const { buildPayload = noop, path, method, fetchOptions } = generatorProps

    const payload = buildPayload(...props)

    const accessToken = getAccessToken(store.getState())

    const [url, fetchConfig] = buildFetch(
      {
        path,
        method,
        accessToken,
        payload,
      },
      { accessToken, ...fetchOptions }
    )

    const response = await fetch(url, fetchConfig)

    return resolveOrReject(response, null, false)
  }

const debug = {
  all: ['debug'],
}

const fetchDebug = async () => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch({ path: 'debug' }, { accessToken })

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useDebugQuery = () => {
  const result = useQuery(debug.all, fetchDebug)

  return result
}
