import { events } from '@tellonym/core/events'
import {
  DETECTABLE_LANGUAGE,
  langDetectObjectsByType1,
} from '@tellonym/enums/lib/Language'
import {
  ARTIFICIAL_TELL_STATUS,
  ARTIFICIAL_TELL_TARGET_GENDER,
} from '@tellonym/enums/lib/Tell'
import { Button, Card, Checkbox, Input, Typography } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box, Modal, theme } from '../../common'
import {
  clearSpeedTranslations,
  createVariances,
  generateSpeedTranslations,
  refreshTopic,
} from '../actionsV2'
import {
  getIsRefreshingSpeedTranslations,
  getIsRefreshingSpeedTranslationsByGender,
  getSpeedTranslationsByGroupId,
} from '../selectorsV2'
import { CREATE_VARIANCES_SUCCESS } from '../typesV2'

const styles = {
  mainContainer: {
    backgroundColor: theme.colors.background,
    minHeight: window.innerHeight - 50,
    minWidth: 800,
    padding: 8,
  },
}

const genders = [
  ARTIFICIAL_TELL_TARGET_GENDER.BOTH,
  ARTIFICIAL_TELL_TARGET_GENDER.MALE,
  ARTIFICIAL_TELL_TARGET_GENDER.FEMALE,
]

const CardHeader = ({
  group,
  groupIndex,
  groupAmount,
  lang,
  close,
  sourceLanguage,
}) => (
  <Box>
    <Box flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography.Text strong>{`${
        groupIndex + 1
      }/${groupAmount}`}</Typography.Text>

      <Typography.Text>{`${sourceLanguage} → ${lang}`}</Typography.Text>

      <Button size="small" onClick={close}>
        x
      </Button>
    </Box>
    <Typography.Text type="secondary">Group Name</Typography.Text>
    <Typography.Text strong style={{ fontSize: 16 }}>
      {group.name}
    </Typography.Text>
    {Boolean(group.description) && (
      <>
        <Typography.Text type="secondary">Description</Typography.Text>
        <Typography.Text>{group.description}</Typography.Text>
      </>
    )}
  </Box>
)

const CardInner = ({
  children,
  gender,
  isRefreshing = false,
  isTranslation,
  onPressRegenerate,
}) => {
  return (
    <Card
      type="inner"
      size="small"
      loading={isRefreshing}
      title={isTranslation ? '' : ARTIFICIAL_TELL_TARGET_GENDER[gender]}
      style={{ flex: 1 }}
      extra={
        isTranslation ? (
          <a
            // eslint-disable-next-line react/jsx-no-script-url
            href="javascript:void(0)"
            onClick={() => {
              onPressRegenerate(gender)
            }}>
            Regenerate
          </a>
        ) : undefined
      }>
      {children}
    </Card>
  )
}

const ItemBase = ({ text }) => (
  <Box>
    <Typography.Text>{text}</Typography.Text>
  </Box>
)

const ItemTranslation = ({ text, index, gender, setVariance }) => {
  const [inputValue, setInputValue] = React.useState(text)
  const [isAccepted, setIsAccepted] = React.useState(false)

  const onChange = (e) => {
    setInputValue(e.target.value)
  }

  const onToggleAccept = (e) => {
    if (e.target.checked) {
      setIsAccepted(true)
      setVariance(gender, index, inputValue)
    } else {
      setIsAccepted(false)
      setVariance(gender, index, undefined)
    }
  }

  return (
    <Box flexDirection="row" alignItems="center" gap={8} paddingVertical={2}>
      <Input.TextArea
        autoSize
        disabled={isAccepted}
        bordered={false}
        showCount={false}
        onChange={onChange}
        value={inputValue}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.025)',
          borderWidth: 1,
          borderStyle: 'dotted',
          borderColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />
      <Checkbox
        name="isAccepted"
        checked={isAccepted}
        onChange={onToggleAccept}
      />
    </Box>
  )
}

const ModalSpeedTranslationComponent = ({ language, modalId, topic }) => {
  const dispatch = useDispatch()

  const [groupIndex, setGroupIndex] = React.useState(0)

  /**
   * We store potentially modified variances under the same index as the original
   * translations.
   */
  const [variances, setVariances] = React.useState({
    [ARTIFICIAL_TELL_TARGET_GENDER.BOTH]: [],
    [ARTIFICIAL_TELL_TARGET_GENDER.MALE]: [],
    [ARTIFICIAL_TELL_TARGET_GENDER.FEMALE]: [],
  })

  const clearVariances = () => {
    setVariances({
      [ARTIFICIAL_TELL_TARGET_GENDER.BOTH]: [],
      [ARTIFICIAL_TELL_TARGET_GENDER.MALE]: [],
      [ARTIFICIAL_TELL_TARGET_GENDER.FEMALE]: [],
    })
  }

  const setVariance = (gender, index, text) => {
    setVariances((variances) => {
      const newVariances = [...variances[gender]]
      newVariances[index] = text

      return {
        ...variances,
        [gender]: newVariances,
      }
    })
  }

  const hasVariances = genders.some((gender) =>
    variances[gender].some((content) => Boolean(content))
  )

  const speedTranslationsByGroupId = useSelector(getSpeedTranslationsByGroupId)

  const isRefreshingSpeedTranslations = useSelector(
    getIsRefreshingSpeedTranslations
  )

  const isRefreshingSpeedTranslationsByGender = useSelector(
    getIsRefreshingSpeedTranslationsByGender
  )

  const allGroups = useSelector(
    (state) => state.artificialTellsV2.topicDetails?.[topic.id]?.groups ?? []
  )

  const notLocalizedGroups = allGroups.filter(
    (group) => group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED
  )

  const groups = notLocalizedGroups.length > 0 ? notLocalizedGroups : allGroups

  const group = groups[groupIndex] ?? {}

  const speedTranslations = speedTranslationsByGroupId[group.id]

  const lang = React.useMemo(() => {
    const langUpper =
      DETECTABLE_LANGUAGE[langDetectObjectsByType1[language]] ?? ''

    return `${langUpper[0]}${langUpper.substring(1).toLowerCase()}`
  }, [language])

  const sourceLanguage = React.useMemo(() => {
    const langUpper =
      DETECTABLE_LANGUAGE[speedTranslations?.sourceLanguage ?? 2] ?? ''

    return `${langUpper[0]}${langUpper.substring(1).toLowerCase()}`
  }, [speedTranslations?.sourceLanguage])

  const close = () => {
    Modal.hide({ id: modalId })
  }

  const onPressSkip = () => {
    if (groupIndex === groups.length - 1) {
      close()
    } else {
      setGroupIndex((groupIndex) => groupIndex + 1)
    }
  }

  const onPressSave = () => {
    dispatch(
      createVariances({
        variances: genders.reduce(
          (acc, gender) =>
            acc.concat(
              variances[gender]
                .filter((content) => Boolean(content))
                .map((content) => ({
                  content,
                  gender,
                }))
            ),
          []
        ),
        groupId: group.id,
      })
    )

    onPressSkip()
  }

  const onPressRegenerate = (gender) => {
    dispatch(
      generateSpeedTranslations(
        {
          groupId: group.id,
          prevResponseQuestions: speedTranslations.translated[gender],
        },
        { gender }
      )
    )
  }

  React.useEffect(() => {
    clearVariances()

    if (!group.id) {
      return
    }

    if (groupIndex === 0) {
      dispatch(generateSpeedTranslations({ groupId: group.id }))
    }

    const nextGroup = groups[groupIndex + 1]

    if (nextGroup) {
      dispatch(generateSpeedTranslations({ groupId: nextGroup.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupIndex])

  React.useEffect(() => {
    const subscription = events.addListener(CREATE_VARIANCES_SUCCESS, () => {
      Alert.success('Variances created successfully')
    })

    if (!group.id) {
      close()
    }

    return () => {
      events.clearListener(subscription)
      dispatch(clearSpeedTranslations())
      dispatch(refreshTopic({ topicId: topic.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!group.id) {
    return null
  }

  return (
    <Box flex={1} justifyContent="center" alignItems="center" transparent>
      <Modal.Body style={styles.mainContainer}>
        <Box flex={1}>
          <Card
            loading={!speedTranslations && isRefreshingSpeedTranslations}
            size="small"
            title={
              <CardHeader
                group={group}
                groupIndex={groupIndex}
                groupAmount={groups.length}
                lang={lang}
                close={close}
                sourceLanguage={sourceLanguage}
              />
            }>
            {genders.map((gender) => (
              <Box key={gender} flexDirection="row">
                <CardInner gender={gender}>
                  {speedTranslations?.original?.[gender]?.map((text) => (
                    <ItemBase key={text} text={text} />
                  ))}
                </CardInner>
                <CardInner
                  gender={gender}
                  isTranslation
                  isRefreshing={isRefreshingSpeedTranslationsByGender[gender]}
                  onPressRegenerate={onPressRegenerate}>
                  {speedTranslations?.translated?.[gender]?.map(
                    (text, index) => (
                      <ItemTranslation
                        key={text}
                        text={text}
                        index={index}
                        gender={gender}
                        setVariance={setVariance}
                      />
                    )
                  )}
                </CardInner>
              </Box>
            ))}
          </Card>
        </Box>

        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          paddingTop={8}
          gap={16}>
          <Button size="large" type="dashed" onClick={onPressSkip}>
            Skip
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={onPressSave}
            disabled={!hasVariances}>
            Save
          </Button>
        </Box>
      </Modal.Body>
    </Box>
  )
}

const show = (payload) =>
  Modal.show({
    render: (props) => (
      <ModalSpeedTranslationComponent {...payload} {...props} />
    ),
    hasBackdrop: true,
    shouldHideOnBackgroundPress: false,
  })

export const ModalSpeedTranslation = { show }
