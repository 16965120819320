import moment from 'moment'
import {
  compose,
  filter,
  map,
  mergeRight,
  path,
  pathOr,
  pick,
  prop,
  sort,
  values,
} from 'ramda'
import { createSelector } from 'reselect'
import { name } from './types'

export const isTimestampBefore = (a, b) =>
  moment(a.lastSeen).isBefore(b.lastSeen) ? 1 : -1

const getState = prop('moderator')

export const getAccountVerifications = (state) =>
  state[name].accountVerifications

export const getBanCandidates = createSelector(getState, prop('banCandidates'))

export const get_rerenderItem = (state) =>
  state[name].banCandidates._rerenderItem

export const getIsRefreshing = (state) => state[name].banCandidates.isRefreshing

export const getIsFetching = (state) => state[name].banCandidates.isFetching

export const getHasMore = (state) => state[name].banCandidates.hasMore

export const getHasTakenModerationAction = (state) =>
  state[name].hasTakenModerationAction

export const getBanCandidatesData = createSelector(
  getState,
  path(['banCandidates', 'data'])
)

export const getBannedDevicesData = createSelector(
  getState,
  path(['bannedDevices', 'data'])
)

export const getNsfwPictures = createSelector(getState, prop('nsfwPictures'))

export const getModeration = createSelector(getState, prop('moderation'))

export const getCurrentReportUserId = createSelector(
  getModeration,
  path([0, 'user', 'id'])
)

export const getResults = createSelector(getState, path(['results', 'data']))

export const getIsRefreshingResults = createSelector(
  getState,
  path(['results', 'isRefreshing'])
)

export const Moderation = (state) => ({
  moderation: state[name].moderation,
  isLoading: state[name].isLoading,
  moderationOpenReports: state[name].moderationOpenReports,
})

export const Result = (state) => state[name].results

export const Search = (state) => ({
  ...state[name].profiles,
  hasMore: state[name].profiles.hasMore,
  isRefreshing: state[name].profiles.isRefreshing,
  hasSearched: state[name].hasSearched,
  profiles: state[name].profiles.data,
})

export const getAnswers = createSelector(getState, prop('answers'))

export const getDevices = createSelector(getState, prop('devices'))

export const getUsersSharingDevices = (state) => state[name].usersSharingDevices

export const getUsersSharingDevicesByDeviceUUID = (state) =>
  state[name].usersSharingDevicesByDeviceUUID

export const getIapEntries = createSelector(getState, prop('iapEntries'))

export const getLogEntries = createSelector(getState, prop('logEntries'))

export const getTells = createSelector(getState, prop('tells'))

export const getSentTells = createSelector(getState, prop('sentTells'))

export const getLastDevices = (state, props) =>
  createSelector(getDevices, (devicesObj) => {
    const { profileId } = props
    const devicesRaw = pathOr({}, ['data', profileId, 'data'], devicesObj)

    const devices = compose(
      values,
      map((item) =>
        mergeRight(
          { device: pathOr('n/o', ['identifier', 'name'], item) },
          pick(['firstSeen', 'lastSeen', 'lastIp'], item)
        )
      )
    )(devicesRaw)

    const devicesLastTwoMonths = filter((item) => {
      if (!item.lastSeen) {
        return null
      }

      const lastSeen = moment(item.lastSeen).format()
      const bestBefore = moment().subtract(2, 'months').format()

      return moment(lastSeen).isAfter(bestBefore)
    }, devices)

    if (devicesLastTwoMonths.length) {
      return devicesLastTwoMonths
    }

    // sort devices by lastSeen and return the most recent
    const devicesOrdered = sort(isTimestampBefore, devices)

    return [devicesOrdered[0]]
  })(state)

export const getLastIpAddress = (state, props) =>
  createSelector(getLogEntries, (logEntriesObj) => {
    const { profileId } = props
    const logEntries = pathOr({}, ['data', profileId, 'data'], logEntriesObj)

    const logEntriesFiltered = compose(
      values,
      filter((entry) => entry && entry.ip && typeof entry.ip === 'string')
    )(logEntries)

    const logEntriesSorted = sort((e1, e2) => {
      const time1 = moment(e1.time).format()
      const time2 = moment(e2.time).format()

      if (moment(time1).isBefore(time2)) {
        return 1
      }
      return -1
    }, logEntriesFiltered)

    return pathOr('', [[0], 'ip'], logEntriesSorted)
  })(state)

const getIssuerUserId = (_, props) => props.issuerUserId

const getReportedUserId = (_, props) => props.reportedUserId

const getChats = createSelector(getState, prop('chats'))

export const getChatMessages = createSelector(
  getChats,
  getReportedUserId,
  getIssuerUserId,
  (chats, reportedUserId, issuerUserId) => {
    if (
      reportedUserId &&
      issuerUserId &&
      chats[reportedUserId] &&
      chats[reportedUserId][issuerUserId] &&
      chats[reportedUserId][issuerUserId].data
    ) {
      return chats[reportedUserId][issuerUserId].data
    }

    return []
  }
)

export const TabAnswers = (state) => state[name].answers

export const TabComments = (state) => state[name].comments

export const TabDevices = (state) => state[name].devices

export const TabIaps = (state) => state[name].iapEntries

export const TabLogs = (state) => state[name].logEntries

export const TabTells = (state) => state[name].tells

export const TabSentTells = (state) => state[name].sentTells

export const IapRestore = (state) => state[name].iapRestore

export const Blacklists = (state) => state[name].blacklists

export const Followers = (state) => state[name].followers

export const Followings = (state) => state[name].followings

export const CommentsForPost = (state) => state[name].commentsForPost
