import { getIsLoggedIn } from '@tellonym/core/app/selectors'
import { logout } from '@tellonym/core/profile/actions'
import { Menu } from 'antd'
import React from 'react'
import ScrollView from 'react-native-web/dist/exports/ScrollView'
import * as ReactRedux from 'react-redux'
import { connect, useSelector } from 'react-redux'
import { useLocation, withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getIsSidemenuShown, getPermissions } from '../../app/selectors'
import { View } from '../../common/components/View'
import * as helpers from '../../common/helpers'
import { history } from '../../common/history'
import { theme } from '../../common/styles/theme'
import { routes, sidemenu } from '../routes'
import { Header } from './Header'

const MenuComponent = () => {
  const dispatch = ReactRedux.useDispatch()
  const location = useLocation()
  const permissions = useSelector(getPermissions)

  const onPress = ({ key, domEvent }) => {
    if (key === 'logout') {
      dispatch(logout())
      return
    }

    const queryParams = location.pathname.includes('/artificialtells_v2')
      ? undefined
      : location.search.split('?')[1]

    const path =
      routes.data[key]?.getPath?.() ??
      `${key}${queryParams ? `?${queryParams}` : ''}`

    if (domEvent.metaKey || domEvent.ctrlKey) {
      window.open(path, '_blank')
      return
    }

    history.push(path)
  }

  const onPressAux = (event) => {
    const fullKey = event.target.closest('[data-menu-id]')?.dataset.menuId

    if (!fullKey) {
      return
    }

    // Extract the actual route key using regex
    // This matches everything after 'rc-menu-uuid-' followed by numbers and dashes
    const key = `/${fullKey.replace(/^rc-menu-uuid-[\d-]+\//, '')}`

    if (key === 'logout') {
      dispatch(logout())

      return
    }

    const queryParams = location.pathname.includes('/artificialtells_v2')
      ? undefined
      : location.search.split('?')[1]

    const path =
      routes.data[key]?.getPath?.() ??
      `${key}${queryParams ? `?${queryParams}` : ''}`

    window.open(path, '_blank')
  }

  const items = React.useMemo(() => {
    const menuItems = sidemenu.ids.map((sectionId) => {
      const {
        permissions: requiredPermission,
        name,
        routes: sideMenuRoutes,
      } = sidemenu.data[sectionId]
      const hasPermission = requiredPermission
        ? helpers.checkPermission(requiredPermission, permissions)
        : true

      if (!hasPermission) {
        return null
      }

      return {
        key: sectionId,
        type: 'group',
        label: name,
        children: [
          ...sideMenuRoutes.map((id) => ({
            key: id,
            label: routes.data[id].name,
          })),
          { type: 'divider' },
        ],
      }
    })

    return [
      ...menuItems,
      { type: 'divider' },
      { key: 'logout', label: 'Logout', danger: true },
      null,
    ]
  }, [permissions])

  return (
    <Menu
      onClick={onPress}
      onAuxClick={onPressAux}
      activeKey={location.pathname}
      items={items}
      selectedKeys={[location.pathname]}
    />
  )
}

class SideMenuComponent extends React.Component {
  static width = 175

  render() {
    const { children, isLoggedIn, isSidemenuShown } = this.props

    return isLoggedIn ? (
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          height: '100%',
          paddingTop: Header.height,
        }}>
        {isSidemenuShown && (
          <View
            style={{
              position: 'fixed',
              boxShadow: theme.styles.shadow,
              top: Header.height,
              left: 0,
              bottom: 0,
              backgroundColor: theme.colors.background,
              zIndex: 98,
              width: SideMenuComponent.width,
              justifyContent: 'space-between',
            }}>
            <ScrollView style={{ flex: 1 }}>
              <MenuComponent />
            </ScrollView>
          </View>
        )}
        {isSidemenuShown && (
          <View style={{ minWidth: SideMenuComponent.width }} />
        )}
        {children}
      </View>
    ) : (
      children
    )
  }
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: getIsLoggedIn,
  isSidemenuShown: getIsSidemenuShown,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ logout }, dispatch),
})

export const SideMenu = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideMenuComponent)
)
